@import 'styles/colors.scss';
@import 'styles/media.scss';

// Mobile first
// 0 - 599
.page {
    display: flex;
    flex-direction: column;
    height: 100%;

    .flexGrow {
        flex-grow: 1;
    }

    .zoomSeries {
        .background {
            margin-top: 6rem;
            background-color: $brand-dark-blue;
            display: flex;
            flex-direction: column;
    
            .heading {
                color: #fff;
                text-align: center;
            }
    
            .content {
                background-color: #fff;
                flex-grow: 1;
                margin: 1rem 3rem 3rem 3rem;
            }
        }
        
    }
}
// 600 - 899
@include tablet-portrait {

}

// 900 - 1199
@include tablet-landscape {
    .page {
        .zoomSeries {
            .background {
                .content {
                    margin: 2rem 6rem 6rem 6rem;
                }
            }
            
        }
    }
}

// 1200 - 1799
@include desktop {

}

// 1800+
@include big-desktop {

}
