@import 'styles/colors.scss';
@import 'styles/media.scss';

// Mobile first
// 0 - 599
.home {
    margin: 1rem;
    font-size: 1.4rem;
    height: 100%;

    .wrap {
        text-align: center;

        img {
            max-height: 30rem;
            margin-bottom: 4rem;
        }
    }


    p {
        margin-bottom: 2.3rem;
        line-height: 2.7rem;
    }
}

// 600 - 899
@include tablet-portrait {
    .home {
        font-size: 1.6rem;
    
        .wrap {
            img {
                float: left;
                margin-top: 6px;
                margin-right: 5rem;
                max-height: 50rem;
            }
        }
    }
}

// 900 - 1199
@include tablet-landscape {

}

// 1200 - 1799
@include desktop {

}

// 1800+
@include big-desktop {

}
