@import 'styles/colors.scss';
@import 'styles/media.scss';

// Mobile first
// 0 - 599
.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(325px, 1fr));
    grid-auto-rows: 1fr;
    /* This is better for small screens, once min() is better supported */
    /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
    grid-gap: 1rem;
    /* This is the standardized property now, but has slightly less support */
    /* gap: 1rem */

    justify-items: center;
  }
// 600 - 899
@include tablet-portrait {

}

// 900 - 1199
@include tablet-landscape {

}

// 1200 - 1799
@include desktop {

}

// 1800+
@include big-desktop {

}
