@import 'styles/colors.scss';
@import 'styles/media.scss';

// Mobile first
// 0 - 599
.competition {
    height: 100%;
    margin-top: 7rem;

    .center {
        text-align: center;
    }

    .pad {
        margin-bottom: 4rem;
    }

    .info{
        position: relative;
        margin-top: 7rem;
        min-height: 100rem;
        height: 100%;

        .tab {
            display: inline-flex;
            font-weight: 600;
            cursor: pointer;
            justify-content: center;
            align-items: center;
            width: 50%;
            height: 5rem;

            &.pink {
                background-color: $brand-pink;
                color: #fff;
            }

            &.green {
                background-color: $brand-blue;
                color: #fff;
            }

            &.yellow {
                background-color: $brand-yellow;
            }
        }

        .blip {
            position: absolute;
            overflow: hidden;

            transition: width .5s ease-in-out, height .5s ease-in-out, transform .5s ease-in-out;

            &.pink {
                background-color: $brand-pink;
            }

            &.green {
                background-color: $brand-blue;
            }

            &.yellow {
                background-color: $brand-yellow;
            }

            &.page {
                height: 0;
                width: 0;
                top: 5rem;
                
                &.left {
                    left: 0;
                }

                &.right {
                    right: 0;
                }

                .content {
                    position: relative;
                    margin: 3rem;
                    padding: 3rem;
                    background-color: $system-light;

                    .judges,
                    .winning,
                    .call {
                        margin-bottom: 4rem;

                        p {
                            margin: 2rem 0;
                        }
                    }

                    .winning {
                        a {
                            display: block;
                        }
                    }

                    .call {
                        ol > li {
                            margin-bottom: 1rem;
                        }

                        ol.alpha {
                            list-style: lower-alpha;
                        }
                    }
                    
                    ul,
                    ol {
                        margin-left: 1.5rem;
                    }
                }
            }

            &.open {
                width: 100%;
                height: 100%;
                transform: rotate(360deg);
                overflow-y: auto;
            }
        }
    }
}
// 600 - 899
@include tablet-portrait {

}

// 900 - 1199
@include tablet-landscape {

}

// 1200 - 1799
@include desktop {

}

// 1800+
@include big-desktop {

}
