@import 'styles/colors.scss';
@import 'styles/media.scss';

// Mobile first
// 0 - 599
.media {
    height: 100%;
    margin-top: 7rem;

    .holidayConcert {
        margin-top: 7rem;

        img {
            max-width: 100%;
        }

        caption {
            white-space: nowrap;
        }
    }
}
// 600 - 899
@include tablet-portrait {

}

// 900 - 1199
@include tablet-landscape {

}

// 1200 - 1799
@include desktop {

}

// 1800+
@include big-desktop {

}
