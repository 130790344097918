@import 'styles/colors.scss';
@import 'styles/media.scss';

.zoomEvent {
    padding: 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:not(:first-child) {
        border-top: 1px solid #000;
    }

    .image {
        height: 16rem;
    }

    .info {
        margin: 2rem;
    }

    .register {
        color: #fff;
        font-weight: bold;
        text-decoration: none;
        background-color: $brand-pink;
        padding: 1rem;
    }
}

// 600 - 899
@include tablet-portrait {
}

// 900 - 1199
@include tablet-landscape {
    .zoomEvent {
        padding: 2rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    
        &:not(:first-child) {
            border-top: 1px solid #000;
        }
    
        .image {
            height: 16rem;
        }
    
        .info {
            margin: 0 14rem;
        }
    
        .register {
            color: #fff;
            font-weight: bold;
            text-decoration: none;
            background-color: $brand-pink;
            padding: 1rem;
        }
    }
}

// 1200 - 1799
@include desktop {

}

// 1800+
@include big-desktop {

}
