*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    height: 100vh;
    font-size: 62.5%;
}

body {
    box-sizing: border-box;
    font-size: 1.6rem;
    font-family: 'Montserrat', sans-serif;
}

body,
#root {
    height: 100%;
}
