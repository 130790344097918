@import 'styles/colors.scss';
@import 'styles/media.scss';

// Mobile first
// 0 - 599
.footer {
    margin-top: 6rem;
    background-color: $brand-dark-blue;
    color: $system-light;
    padding: 2rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .sectionHeader {
        font-size: 1.8rem;
        margin-bottom: 1rem;
        font-weight: 600;
        letter-spacing: .25rem;
    }

    .mission {
        margin: 4rem 0;
        text-align: center;
    }
}
// 600 - 899
@include tablet-portrait {
    .footer {
        flex-direction: row;

        .mission {
            margin-right: 4rem;
            max-width: 50rem;
        }
    }
}

// 900 - 1199
@include tablet-landscape {

}

// 1200 - 1799
@include desktop {

}

// 1800+
@include big-desktop {

}
