$system-dark: #241909;
$system-light: #f2f7f2;

$background-light: #e5e5e5;

$brand-pink: #DE4D86;

$brand-yellow: #F6C5AF;

$brand-blue: #086788;
$brand-dark-blue: #114B5F;
