@import 'styles/colors.scss';
@import 'styles/media.scss';

// Mobile first
// 0 - 599
.video {
    width: 30rem;
    padding: 1rem;
    border-radius: .5rem;
    border: 1px solid #fff;
    font-weight: bold;
    color: #fff;

    &:nth-of-type(3n + 0) {
        border-color: $brand-blue;
        background-color: $brand-blue;
    }
    &:nth-of-type(3n + 1) {
        border-color: $brand-yellow;
        background-color: $brand-yellow;
        color: $system-dark;
    }
    &:nth-of-type(3n + 2) {
        border-color: $brand-pink;
        background-color: $brand-pink;
    }

    .display {
        text-align: center;
        border-radius: .5rem .5rem 0 0;

        .iframe {
            width: 28rem;
        }
    }
    .description {
        border-radius: 0 0 .5rem .5rem;
        margin-top: 3rem;
        margin-bottom: 1rem;
    }
}
// 600 - 899
@include tablet-portrait {

}

// 900 - 1199
@include tablet-landscape {

}

// 1200 - 1799
@include desktop {

}

// 1800+
@include big-desktop {

}
