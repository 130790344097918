@import 'styles/colors.scss';
@import 'styles/media.scss';

// Mobile first
// 0 - 599
header {
    position: relative;
    display: flex;
    flex-direction: column;

    .header {
        align-items: center;
        background-color: $brand-pink;
        background: linear-gradient(0deg, $brand-pink 0%, rgba(255,200,200,1) 100%);
        color: #fff;
        display: flex;
        flex-direction: column;
        font-size: 2rem;
        padding: 1rem 3rem;
        white-space: nowrap;
    }

    .toggle {
        cursor: pointer;
        font-size: 1.6rem;

        .caret {
            color: white;
            height: 1.25rem;
            margin-left: .5rem;
            margin-top: .5rem;
            transition: height 1s ease-in-out;

            &.open {
                transform: rotate((-180deg));
            }
        }
    }

    nav {
        height: 0;
        overflow: hidden;
        transition: height 1s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        &.expander {
            background-color: #f0f0f0;

            .contact {
                text-decoration: none !important;
                cursor: unset !important;

                .info {
                    display: none;
                }
            }

            &.open {
                height: 14.2rem;
            }

            .link {
                color: $brand-pink;
                display: inline-block;
                width: 100%;
                padding: .75rem;
                cursor: pointer;

                &.contrast {
                    background-color: #e5e5e5;
                }

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

// 600 - 899
@include tablet-portrait {
   
}

// 900 - 1199
@include tablet-landscape {
    header {
        flex-direction: row;
    
        .toggle {
            display: none;
        }
    
        nav {
            overflow: visible;
            height: inherit;
            align-items: center;
            transition: none;
            flex-grow: 1;
            flex-direction: row;
            background: rgb(232,63,102);
            background: linear-gradient(0deg, $brand-pink 0%, rgba(255,200,200,1) 100%);
    
            &.expander {
                padding-right: 3rem;

                .contact {
                    position: relative;
                    cursor: pointer !important;

                    
                    
                    &:hover {
                        text-decoration: underline !important;
                    }
        
                    .inlineContact {
                        display: none;
                    }

                    .info {
                        height: 0;
                        padding: 0;
                        display: flex;
                        transition: height .5s, padding .5s;
                        overflow: hidden;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        top: 4rem;
                        right: 0;
                        
                        
                        &.open {
                            height: 5rem;
                            background-color: $brand-pink;
                            background-color: #DE4D86;
                            padding: 3rem;
                        }
                    }
                }

                &.open {
                    height: initial;
                }
                .link {
                    color: #fff;
                    cursor: pointer;
                    white-space: nowrap;
    
                    &.contrast {
                        background-color: inherit;
                    }
    
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

// 1200 - 1799
@include desktop {

}

// 1800+
@include big-desktop {

}
