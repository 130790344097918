@import 'styles/colors.scss';
@import 'styles/media.scss';

// Mobile first
// 0 - 599
.subHeading {
    text-align: center;
    font-size: 2rem;
    margin-top: 3rem;
    margin-bottom: 6rem;
    font-weight: 600;
    letter-spacing: .25rem;

    .separator {
        margin: 0 1rem;
        color: $brand-pink;
    }
}
